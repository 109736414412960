/* eslint-disable @typescript-eslint/ban-ts-comment */
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useRoutingStore } from '~/stores/routing'

/*
 * STANDARD EVENT LIBRARY TYPES AND INTERFACES
 */
type StandardLibraryEventType = Record<
  string,
  (
    this: ComponentPublicInstance,
    additionalContent: Record<string, any>,
  ) => void
>
type AvailableStandardEventKeys = 'ga'
type AvailableEventKeys = AvailableStandardEventKeys
type StandardEventTranslationLibrary = Record<
  AvailableStandardEventKeys,
  StandardLibraryEventType
>

/*
 * EVENT TYPES AND INTERFACES
 */
interface EventMinArgs {
  category: string
  action: string
  label?: string
}

type EventAcceptedData = Record<string, any> & EventMinArgs

interface EventRequiredData extends EventMinArgs {
  previousUrl?: string
  hash?: string
}
type EventData = Record<string, any> & EventRequiredData
interface InternEventDetails {
  details: Record<string, any>
}
// type InternDetails = InternEventDetails & EventData

/**
 * @deprecated Use helpers/LegacyTrackEvent.ts.
 */
export default defineComponent({
  computed: {
    ...mapState(useRoutingStore, { PREVIOUS_URL: 'PREVIOUS' }),
    translationLib(): StandardEventTranslationLibrary {
      return {
        ga: {
          purchase(additionalContent: Record<string, any>) {
            // const userStore = useUserStore(getActivePinia())
            // const id = userStore.id + '__' + new Date().getTime()
            // this.$ga.ecommerce.addTransaction({
            //   id: id,
            //   affiliation: additionalContent.label,
            //   revenue: additionalContent.value,
            //   shipping: '0',
            //   tax: '0',
            //   //@ts-ignore Trust
            //   currency: 'EUR',
            // })
            // this.$ga.ecommerce.addItem({
            //   id,
            //   name:
            //     additionalContent.pack_name || additionalContent.content_ids[0],
            //   price: additionalContent.value,
            //   quantity: 1,
            //   //@ts-ignore Trust
            //   currency: 'EUR',
            // })
            // this.$ga.ecommerce.send()
          },
          completeRegistration() {
            // this.$ga.event({
            //   eventCategory: 'Signup',
            //   eventAction: 'Signup Artist Succeeded',
            //   eventValue: userStore.id,
            // })
          },
        },
      }
    },
  },
  methods: {
    getLaunchArray(): AvailableEventKeys[] {
      const ret: AvailableEventKeys[] = ['ga']

      return ret
    },
    /**
     * @param actionName
     * @param _label
     * @param additionalContent
     * @deprecated Use helpers/LegacyTrackEvent.ts.
     */
    trackStandardEvent(
      actionName: string,
      _label: string,
      additionalContent: string | Record<string, any>,
    ) {
      this.getLaunchArray().forEach((eventType: AvailableEventKeys) => {
        if (
          // @ts-ignore Trust
          this.translationLib[eventType] &&
          // @ts-ignore Trust
          typeof this.translationLib[eventType][actionName] === 'function'
        ) {
          // @ts-ignore Trust

          this.translationLib[eventType][actionName].bind(this)(
            additionalContent,
            this,
          )
        }
      })
    },
    /**
     * @param data
     * @deprecated Use helpers/LegacyTrackEvent.ts.
     */
    buildQuery(data: EventData): EventData {
      for (const key in this.$route.query)
        data[`query_${key}`] = this.$route.query[key]

      return data
    },
    /**
     * @param data
     * @deprecated Use helpers/LegacyTrackEvent.ts.
     */
    trackEvent(data: EventAcceptedData) {
      if (typeof data === 'object') {
        if (typeof data.label === 'undefined') data.label = this.$route.path

        data = this.buildQuery(data)

        data.previousUrl =
          this.PREVIOUS_URL || window?.document?.referrer || undefined
        data.hash = this.$route.hash
      } else {
        console.warn('[EVENT] - Missing data object\n')
      }
    },
  },
})
